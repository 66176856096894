<template>
  <div class="container">
    <h2 class="heading">Вакансии</h2>
    <button @click="next(-1)" :disabled="page <= 1">
      <v-icon class="iconChevronL"
              size="4rem"
              v-bind:style=" page ===1 ? 'display: none' : 'display: block'">
        mdi-chevron-left
      </v-icon>
    </button>
    <section class="containerCards">
      <vacancy-card v-for="(item,n) in vacanciesList.slice((page -1)*perPage, page*perPage)" :key="n"
                    :title-vacancy="item.title"
                    :salary-vacancy="item.salary"
                    :additional-text="item.additionalText"
                    :text-vacancy="item.textVacancy"
                    :we-offer-text="item.weOfferText"
                    :what-you-have-to-do-text="item.whatYouHaveToDoText"
                    :stages-of-selection="item.stagesOfSelection">
      </vacancy-card>
    </section>
    <button @click="next(+1)" :disabled="page >= numPages">
      <v-icon class="iconChevronR"
              size="4rem"
              v-bind:style=" page === Math.ceil(vacanciesList.length/3) ? 'display: none' : 'display: block'">
        mdi-chevron-right
      </v-icon>
    </button>
  </div>
</template>

<script>
import VacancyCard from "@/components/Career/VacancyCard.vue";

export default {
  name: "vacancies",
  components: {VacancyCard},
  props: {
    vacanciesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      perPage: 3,
    }
  },
  computed: {
    numPages() {
      return Math.ceil(this.vacanciesList.length / this.perPage);
    },
  },
  methods: {
    next(change) {
      this.page = Math.max(1, Math.min(this.numPages, this.page + change));
    },
  },
}
</script>

<style scoped>
.container {
  position: relative;
}

.containerCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  //gap: 2.5rem;
  @media (max-width: 1260px) {
    justify-content: center;
    gap: 2.5rem;
  }
}

.iconChevronL {
  position: absolute;
  z-index: 1;
  color: orange;
  left: -10%;
  top: 50%;
  @media (max-width: 1360px){
    top: 100%;
    left: 35%;
  }
}

.iconChevronR {
  position: absolute;
  z-index: 1;
  color: orange;
  right: -10%;
  top: 50%;
  @media (max-width: 1360px){
    top: 100%;
    right: 35%;
  }
}
</style>