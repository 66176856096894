<template>
  <v-container style="margin-bottom: 5rem">
    <company-description></company-description>
    <career-cards :cardData="CardData"></career-cards>
    <vacancies :vacanciesList="VacanciesList"></vacancies>
    <form-field></form-field>
  </v-container>
</template>

<script>
import CompanyDescription from "@/components/Career/CompanyDescription.vue";
import CareerCards from "@/components/Career/CareerCards.vue";
import Vacancies from "@/components/Career/Vacancies.vue";
import FormField from "@/components/Career/Form.vue";

export default {
  components: {FormField, Vacancies, CareerCards, CompanyDescription},
  name: "career",
  data() {
    return {
      CardData: [
        {
          phrase: "",
          name: "Козлова Елена",
          fullText: "Являюсь сотрудницей компании БПО с 2017 г. Первое, что подкупило в вакансии о работе это график 1/3." +
              " При наличии 2-х детей, и отсутствии бабушек и дедушек это самый оптимальный график, т.к. больше время проводишь дома. " +
              "Второе, это то что аэропорт Шереметьево, который мне всё когда казался каким то связующим со всем миром., ну и третье зарплата, " +
              "при сравнении зарплаты при работе 5/2 или 1/3, конечно 1/3 выигрывало.\n\nИ в моей жизненной ситуации это оказался самый лучший выбор, о " +
              "котором я никогда за всё время не пожалела. Работа очень интересная, не скажу что лёгкая, в каждой профессии есть какие-то свои издержки, " +
              "бывает всякое, но все-таки больше всегда проходит в положителыных эмоциях. Что касается зарплаты, хочу оговориться сразу, что зарплата зависит только " +
              "от самого себя, от знаний в своей сфере деятельности и умений взаимодействия с пассажиром, налаживании контакта, умении предложить альтернативные варианты, " +
              "если нет того что надо.\n\nПомимо этого это колоссальная ответственность за каждого пассажира с момента выписки билета, и до момента пока он не совершит перелет, " +
              "очень много нюансов особенно в международных перелетах, которые должен знать кассир." +
              "Огромную роль также работе играет внутрення дружественная атмосфера, и взаимодействие руководства с сотрудниками. Тебя выслушают, помогут, подскажут, вне зависимости " +
              "от ситуации.\n\nБольшую поддержку наше руководство оказало в период начала пандемии, когда в то время огромные компании, начали сокращать сотрудников, без сохранения зарплаты, " +
              "или вовсе увольнять сотрудников, наша компания постаралась сохранить всех своих сотрудников, а также оказать финансовую поддержку в тот непростой период, также получилось " +
              "и с началом СВО. За что, огромная благодарность нашему уважаемому руководству! И честно хочу сказать я люблю свою работу!",
          img: "career/Kozlova.jpg",
        },
        {
          phrase: "",
          name: "Сергеева Ника",
          fullText: "Я начала работать в сентябре 2022 года. Уже работаю 10 месяцев. В течении первого месяца с нами постоянно были технологи которые помогали в решении всех вопросов. " +
              "За полтора месяца я уже знала базу системы. Все коллеги дружелюбны, и до сих пор помогают со всеми возникающими вопросами. Мне нравятся условия, зарплата выросла в два раза.",
          img: "career/Sergeeva.jpg",
        },
        {
          phrase: "",
          name: "Чиботару Яна",
          fullText: "Всех приветствую, меня зовут, Яна! Свой путь начала в компании БПО с июня 2022г. " +
              "Обучения для меня далось без особых трудностей, азы Сирены поняла сразу, но когда я стала изучать обмены, и возвраты тут пришлось по потеть, ну ничего я справилась.\n\n" +
              "В работе все нравится, самое главное график работы, можно взять дополнительные смены. " +
              "Всегда можно обратиться к руководителю за помощью по любому вопросу, без внимания не оставят, зарплату выплачивают без задержек. " +
              "Всем Огромное спасибо за работу и заботу!",
          img: "career/Chibotaru.jpg",
        },
        {
          phrase: "",
          name: "Малыхина Светлана",
          fullText: "Приветствую Вас! Меня зовут Светлана. Работаю в компании не так давно, но очень нравится. " +
              "Проходя обучение, в начале было все непонятно. Одну из программ я называла \"Азбукой Морзе\", но наши феи-технологи во всем помогли разобраться." +
              "У нас, в принципе, хороший коллектив. Девочки - коллеги всегда протягивают \"руку помощи\", а для новичков это важно! К руководству можно подойти с любым вопросом и он будет решён. " +
              "Это большой плюс когда руководитель делает все от него зависящее для комфорта сотрудников.\n\n" +
              "Спасибо Вам большое! Я только в начале своего пути и есть куда стремиться!",
          img: "career/Malykhina.jpg",
        },
        {
          phrase: "",
          name: "Брыкова Елена",
          fullText: "Добрый день! Меня зовут Елена, я сотрудник компании БПО с 2017 года. " +
              "Мой путь начался с того что, я окончила курсы Сирены, а компания набирала сотрудников без опыта работы, прошла собеседование успешно. " +
              "Так же я живу в Егорьевске, а аэропорт Домодедово, это самое ближайшее место куда можно добраться до работы. " +
              "\n\n" +
              "Работа очень интересная, научилась многому, так как компания не стоит на месте, и развивается. В аэропорту Домодедово мы ещё являемся представителями авиакомпаний, " +
              "это дает большой толчок для роста новичков, и узнаешь всегда для себя что-то новое. " +
              "Зарплата выплачивается всегда вовремя, в работе нужно понимать все зависит от самого себя, как настроишься такая и будет продажа.\n\n" +
              "Большую роль играет также атмосфера внутри коллектива, и взаимодействие руководства с коллективом. Коллектив у нас хороший, всегда друг друга выручим, и поможем без этого никак. " +
              "Руководство у нас прекрасное, всегда идут на встречу, поддержат, и помогут в любой ситуации. Отдельное спасибо, вышестоящему руководству, в период пандемии, и СВО не было сокращения штата, зарплату платили вовремя! " +
              "Я рада, что работаю в БПО!",
          img: "career/brykova.jpg",
        },
        {
          phrase: "",
          name: "Фирсова Ирина",
          fullText: "Меня зовут Ирина, в компании работаю с весны 2021 года. " +
              "Изначально в работе заинтересовал удобный график 1/3 (1/2), а также возможность передвинуть несколько смен благодаря руководству и моим коллегам. Для меня это важно, так как помимо работы я обучаюсь в институте.\n\n" +
              "У меня сплоченный коллектив, который готов оперативно помочь в самых сложных ситуациях, подсказать дальнейшие действия.\n\n " +
              "За последние три месяца моя зарплата увеличилась более, чем в два раза. Это произошло благодаря продуктивным продажам и мотивации руководства. Своего пика я еще не достигла, но все впереди!",
          img: "career/Firsova.jpg",
        },
        {
          phrase: "",
          name: "Кулаева Марина",
          fullText: "Здравствуйте, меня зовут Марина! Работаю в агентстве с марта 2023 года. " +
              "В начале обучения было немного сложно, но я, справилась, так как было желание познавать новую сферу работы особенно изучение программ для выписки билетов. " +
              "В нашей профессии нужно быть психологом, так как люди все разные, и по-разному реагируют в стрессовых ситуациях.\n\n" +
              "Очень благодарна за большую поддержку моему наставнику Ольге, всегда на связи, в сложных ситуациях поможет, и подскажет. " +
              "Спасибо нашему руководству - Ирина, Олег, в любых ситуациях всегда поддержат, даже когда сам в себя не веришь!\n\n" +
              "Девочки - коллеги, на первом этапе работы помогают, и подсказывают, а для начинающего сотрудника в первую очередь нужна ПОДДЕРЖКА!",
          img: "career/Kulaeva.jpg",
        }
      ],
      VacanciesList: [
        {
          title: "Начинающий специалист в аэропорт Шереметьево",
          salary: "от 70 000р.",
          additionalText: "с обучением",
          textVacancy: "В связи со стремительным развитием мы ищем активного и инициативного человека в нашу команду.\n" +
              "Если Вы:\n" +
              "- амбициозны;\n" +
              "- инициативны;\n" +
              "- любите и умеете общаться с людьми;\n" +
              "- хотите много зарабатывать и иметь время для личной жизни\n" +
              "то мы ждем именно Вас!",
          weOfferText:"- Оплачиваемое обучение новой профессии (в течение первого месяца работы);\n" +
              "- Постоянно растущий уровень дохода и прозрачную систему мотивации;\n" +
              "- Ежемесячную премию лучшим сотрудникам по итогам месяца;\n" +
              "- Сменный график (сутки/трое) при котором у Вас будет возможность совмещать работу с полноценным отдыхом;\n" +
              "- Отсутствие бюрократии;\n" +
              "- Лояльное руководство, которое оперативно реагирует на проблемы сотрудников и решает их;\n" +
              "- Официальное трудоустройство;\n" +
              "- Работу в международном аэропорту Шереметьево.",
          whatYouHaveToDoText:"- Подбор оптимального маршрута по запросам пассажиров;\n" +
              "- Продажа, обмены, возвраты авиа и ж/д билетов;\n" +
              "- Реализация дополнительных услуг авиакомпаний;\n" +
              "- Работа с кассой;\n" +
              "- Отчётность."+
              "\nПрисоединяйтесь к нашей команде профессионалов. Возможно мы ждём именно Вас!",
          stagesOfSelection:"1. Ваш отклик\n" +
              "2. Телефонное интервью\n" +
              "3. Собеседование в офисе с HR\n" +
              "4. Пробный день и знакомство с командой в аэропорту Шереметьево\n" +
              "5. Приглашение на стажировку\n" +
              "6. Добро пожаловать в команду! "
        },
        {
          title: "Начинающий специалист в аэропорт Домодедово",
          salary: "от 70 000р.",
          additionalText: "с обучением",
          textVacancy: "В связи со стремительным развитием мы ищем активного и инициативного человека в нашу команду.\n" +
              "Если Вы:\n" +
              "- амбициозны;\n" +
              "- инициативны;\n" +
              "- любите и умеете общаться с людьми;\n" +
              "- хотите много зарабатывать и иметь время для личной жизни\n" +
              "то мы ждем именно Вас!",
          weOfferText:"- Оплачиваемое обучение новой профессии (в течение первого месяца работы);\n" +
              "- Постоянно растущий уровень дохода и прозрачную систему мотивации;\n" +
              "- Ежемесячную премию лучшим сотрудникам по итогам месяца;\n" +
              "- Сменный график (сутки/трое) при котором у Вас будет возможность совмещать работу с полноценным отдыхом;\n" +
              "- Отсутствие бюрократии;\n" +
              "- Лояльное руководство, которое оперативно реагирует на проблемы сотрудников и решает их;\n" +
              "- Официальное трудоустройство;\n" +
              "- Работу в международном аэропорту Домодедово.",
          whatYouHaveToDoText:"- Подбор оптимального маршрута по запросам пассажиров;\n" +
              "- Продажа, обмены, возвраты авиа и ж/д билетов;\n" +
              "- Реализация дополнительных услуг авиакомпаний;\n" +
              "- Работа с кассой;\n" +
              "- Отчётность."+
              "\nПрисоединяйтесь к нашей команде профессионалов. Возможно мы ждём именно Вас!",
          stagesOfSelection:"1. Ваш отклик\n" +
              "2. Телефонное интервью\n" +
              "3. Собеседование в аэропорту Домодедово\n" +
              "4. Приглашение на стажировку\n" +
              "5. Добро пожаловать в команду! "
        },
      ],
    }
  }
}
</script>

<style>
.heading{
  color: orange;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  margin: 5rem 0 2rem;
}
</style>