<template>
  <div :style="backgroundStyle" v-bind:class="!show? 'cardBlock': 'cardBlockOpened'">
    <p>{{ name }}</p>
    <p :style="show? {display:'none'}: ''">{{ mainText }}</p>
    <article :style="!show? {display:'none'}: ''">{{ fullText }}</article>
    <div class="moreDetailed" v-on:click="toggleState"
         :style="show? {height: '3.5rem', background: 'rgb(255,165,0)', color: 'white'} :
         {height: '12%', background: 'rgba(255,255,255,0.35)', color: 'white',   textShadow: '10px -5px 15px #000000'}">
      {{ show ? 'Скрыть' : 'Подробнее' }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'successStoryCard',
  props: {
    mainText: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    fullText: {
      type: String,
      required: true,
    },
    backgroundStyle: {
      type: String,
      required: true,
    },
  },
  methods: {
    toggleState: function () {
      this.show = !this.show;
    }
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>

<style scoped>
.cardBlock {
  width: 22rem !important;
  height: 30rem !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(2px) !important;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cardBlockOpened {
  width: 100% !important;
  height: auto !important;
  padding-bottom: 3.5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(7px);
  border-radius: 7px;
  background-image: none !important;
  background: #ececec;
}

.cardBlockOpened p {
  color: orange;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  position: relative;
  top: 2rem;
  left: 2rem;
  width: 90%;
  margin-bottom: 1.5rem;
}

article {
  padding: 2rem;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  color: #262626;
  text-align:justify;
  white-space: break-spaces;
  width: 100%;
  overflow-wrap: break-word;
  hyphens: auto;
  line-height: 40px;
  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 34px;
  }
}

.cardBlock p {
  color: #ffffff;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  position: relative;
  top: 5%;
  text-shadow: 5px -5px 20px #000000;
  margin-bottom: 3rem;
}

.moreDetailed {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  backdrop-filter: blur(2.5px);
  border-radius: 0 0 5px 5px;
}

</style>