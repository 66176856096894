<template>
  <div>
<!--    <p>Здесь слоган</p>-->
    <h2 class="heading">О компании</h2>
<!--    <article>{{ description }}</article>-->
    <article class="fullDescription">
      <p><span>БПО </span>{{description1}}</p>
      <p><span>Наша миссия </span>{{description2}}</p>
      <p><span>Наша отличительная черта </span>{{description3}}</p>
      <p><span>Наша гордость </span>{{description4}}</p>
      <p><span>Наше преимущество </span>{{description5}}</p>
      <p><span>Наши сотрудники </span>{{description6}}</p>
      <p>{{description7}}</p>
    </article>
    <section class="containerPhoto" >
      <div  v-for='photo of photos' :key="photo.class" >
        <v-img
            :src="require(`@/assets/${photo.img}`)"
            width="22rem"
            height= "15rem"
            cover
        >
        </v-img>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "CompanyDescription",
  data() {
    return {
      description1:' — агентство по реализации авиа и ж/д билетов, которое существует на рынке более 20 лет. С каждым годом мы растем и развиваемся, чтобы как можно больше клиентов смогли воспользоваться нашим высококлассным сервисом, и получить радость от путешествия начиная с покупки билета.',
      description2:'— предоставить возможность свободного передвижения людям по всему миру; оказывать услуги, которые дарят людям радость общения, делают их жизнь ярче и интереснее; предоставить каждому клиенту высокий уровень сервиса, широкий ассортимент и конкурентные цены; ' +
          'партнерам – строить долгосрочные, надежные и открытые отношения; персоналу – постоянно развиваться, участвуя с командой единомышленников в жизни компании и получая достойную оценку личного вклада.',
      description3:'— забота о каждом клиенте.',
      description4:'— команда высококлассных специалистов. В нашей команде нет винтиков и шестеренок, каждый член команды для нас ценен и уникален. Сила команды в каждом отдельном сотруднике, сила каждого отдельного сотрудника в команде.',
      description5:'— возможности для легкого старта, личного развития и неограниченного заработка, за счёт одной из лучших бонусных систем в отрасли.',
      description6:'— активные и инициативные люди, которые стремятся повысить свой уровень жизни и понимают разницу между словами "заработать" и "получить".',
      description7:'Работа в БПО это отличная возможность для реализации своих целей, развития себя, как личности и, главное, возможность много зарабатывать. ',
      photos: [
        {
          img: "airplanes/1.png",
        },
        {
          img: "airplanes/1.png",
        },
        {
          img: "airplanes/1.png",
        },
      ],
    }
  }
}
</script>

<style scoped>
.containerPhoto{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  height: auto;
  justify-content: space-between;
  @media (max-width: 1260px) {
    justify-content: center;
  }
  div{
    height: 15rem;
    width: 20rem;
  }
}
.fullDescription{
  text-align: justify;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4rem;
  overflow-wrap: break-word;
  hyphens: auto;
  line-height: 40px;
  white-space: break-spaces;
}
.fullDescription span{
  font-weight: 600;
  color: #64DD17;
}
.fullDescription p{
  margin-top: 1.5rem;
}
</style>