<template>
  <div class="container">
    <h2 class="heading">Форма для отправки резюме</h2>
    <p class="text">Не нашли что искали? Если Вы не нашли интересующую Вас вакансию,
      заполните эту форму. Ваше резюме будет рассмотрено, и мы обязательно свяжемся с Вами,
      когда у нас появится интересное предложение именно для Вас.</p>
    <fieldset>
      <div class="inputs">
        <input class="inputText" type="text" name="fio" id="fio" v-model="form.fio" placeholder="Введите ФИО">
        <input class="inputText" type="text" name="email" id="email" v-model="form.email" placeholder="Введите email">
        <input class="inputText" type="text" name="linkR" id="linkR" v-model="form.linkR" placeholder="Ссылка на ваше резюме">
        <input type="text" class="inputNumberPhone"
               v-model="form.telephone" v-mask="'+7(###)###-##-##'"
               placeholder="+7(_ _ _)-_ _ _ -_ _-_ _"
        >
      </div>
      <textarea v-model="form.about" placeholder="Добавьте немного о себе..."></textarea>
    </fieldset>
    <v-btn dark large color="orange" elevation="5" @click="sendResume"
    >отправить
    </v-btn>
  </div>
</template>

<script>

export default {
  name: "formField",
  data() {
    return {
      form: {
        telephone: '',
        fio: '',
        email: '',
        linkR: '',
        about: '',
      },

    }
  },
  methods: {
    async sendResume() {
      try {
        let dataForm = {
          name: this.form.fio,
          email: this.form.email,
          linkR: this.form.linkR,
          numberPhone: this.form.telephone,
          about: this.form.about,
        };

        console.warn(dataForm)
        let a = await this.$axios.post(this.$store.state.bpoServiceApi+'/Emails/Mail/Send', dataForm);
        console.warn(a)

      }
      catch (e) {
        console.warn(e.response)
      }
    }
  },

}
</script>

<style scoped>
.container {
  text-align: center;
}

.text {
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
}

fieldset {
  border: 0;
  display: flex;
  flex-direction: row;
  padding: 0 3rem;
  justify-content: space-between;
  margin: 3rem 0 2rem;
  @media (max-width: 800px) {
    align-items: center;
    flex-direction: column;
    margin: 3rem 0;
    padding: 0;
  }
}

.inputText {
  padding: 9px 0 10px 10px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.40);
  width: 100%;
}

.inputText:focus {
  outline: 2px solid #64DD17;
  border: 1px solid rgba(0, 0, 0, 0.10);
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 43%;
  @media (max-width: 800px) {
    width: 80%;
  }
}

.inputNumberPhone {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  border-bottom: 2px solid #64DD17;
  padding: 2px 10px 5px 0;
  width: 50%;

}
.inputNumberPhone:focus {
  outline: none;
}


textarea {
  width: 43%;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.40);
  resize: none;
  padding: 10px;
  @media (max-width: 800px) {
    margin-top: 1rem;
    width: 80%;
    height: 20rem;
  }
}
textarea:focus {
  outline: 2px solid #64DD17;
  border: 1px solid rgba(0, 0, 0, 0.10);
}
</style>