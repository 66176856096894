<template>
  <div>
    <div class="cardVacancy">
      <h3>{{ titleVacancy }}</h3>
      <h5>{{ salaryVacancy }}</h5>
      <p>{{additionalText}}</p>
      <div class="moreDetailed" @click="dialog = true">подробнее</div>
    </div>
    <v-dialog
        v-model="dialog"
        width="85%"
    >
      <v-card>
        <v-card-text>
          <div class="modalWindow-header">
            <h3 class="title">{{ titleVacancy }}</h3>
            <div class="modal-close" @click="dialog = false">&#10006;</div>
          </div>
          <p class="p-modal">{{ textVacancy }}</p>
          <h3 class="h3-modal">Мы предлагаем</h3>
          <p class="p-modal">{{ weOfferText }}</p>
          <h3 class="h3-modal">Чем предстоит заниматься:</h3>
          <p class="p-modal">{{ whatYouHaveToDoText }}</p>
          <h3 class="h3-modal">Этапы отбора:</h3>
          <p class="p-modal">{{ stagesOfSelection }}</p>
          <div class="footer-modal">
            <h3 class="footer-h3">Контакты отдела персонала:</h3>
            <p>{{contacts.mainEmail}}</p>
            <p>{{contacts.hrEmail}}</p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "VacancyCard",
  data () {
    return {
      dialog: false,
      contacts: {
        mainEmail: 'hr@bpo.travel',
        hrEmail: 'm.sochkova@bpo.travel'
      }
    }
  },
  props: {
    titleVacancy: {
      type: String,
      required: true,
    },
    salaryVacancy: {
      type: String,
      required: true,
    },
    additionalText: {
      type: String,
      default: ''
    },
    textVacancy: {
      type: String,
      required: false,
    },
    weOfferText: {
      type: String,
      required: false,
    },
    whatYouHaveToDoText: {
      type: String,
      required: false,
    },
    stagesOfSelection: {
      type: String,
      required: false,
    },
  },
  methods: {
    showModal: function () {
      this.$refs.modal.show = true
    },
  },
}
</script>

<style scoped>
.cardVacancy {
  width: 22rem;
  height: 25rem;
  border-radius: 5px;
  background: #F5F5F5;
  position: relative;
}
.cardVacancy h3{
  color: orange;
  padding: 1rem 1rem 0;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
}
.cardVacancy h5{
  color: #64dd17;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  padding: 1rem 1rem 0;
  position: absolute;
  bottom: 7rem;
}
.cardVacancy p{
  position: absolute;
  padding: 1rem 1rem 0;
  bottom: 3.5rem;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}
.moreDetailed {
  position: absolute;
  bottom: 0;
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 152, 0, 0.80);
  color: white;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  border-radius: 0 0 5px 5px;
}
.p-modal {
  padding: 0.2rem 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #262626;
  text-align:justify;
  white-space: break-spaces;
  overflow-wrap: break-word;
  line-height: 1.5rem;
  @media (max-width: 600px) {
    font-size: 18px;
  }
}

.h3-modal{
  color: #64DD17;
  @media (max-width: 600px) {
    font-size: 20px;
  }
}
.modalWindow-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding-top: 1rem;
  @media (max-width: 704px) {
    align-items: start;
  }
}
.title {
  color: orange;
  flex-grow: 3;
  @media (max-width: 600px) {
    width: 75%;
    font-size: 20px;
  }
}
.modal-close {
  border-radius: 70%;
  color: #fff;
  background: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.footer-modal{
  display: flex;
  margin-top: 0.5rem;
  gap: 2rem;
  flex-wrap: wrap;
  @media (max-width: 900px) and (min-width: 400px){
    justify-content: center;
    gap: 0.5rem;
  }
  @media (max-width: 400px){
    justify-content: center;
    text-align: center;
    gap: 0.2rem;
  }
}
.footer-h3{
  color: orange;
  @media (max-width: 900px){
    text-align: center;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 1rem;
  }
}
.footer-modal p{
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #262626;
}
.modalWindow::-webkit-scrollbar {
  background: orange;
  width: 5px;
}

.modalWindow::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F5F5F5;
}

.modalWindow::-webkit-scrollbar-thumb {
 border-radius: 10px;
  background-color: orange;
}

</style>