<template>
  <div>
    <h2 class="heading">История успеха</h2>
    <section class="containerImages">
      <success-story-card v-for="(item,n) in cardData" :key="n"
                          :main-text="item.phrase"
                          :full-text="item.fullText"
                          :name="item.name"
                          :backgroundStyle="{ backgroundImage: 'url(' + require(`@/assets/${item.img}`) + ')',
      width: '22rem', height: '30rem', backgroundBlendMode: 'darken'}"
      >
      </success-story-card>
    </section>
  </div>
</template>

<script>

import SuccessStoryCard from "@/components/Career/SuccessStoryCard.vue";

export default {
  name: "careerCards",
  components: {SuccessStoryCard},
  props: {
    cardData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },

}
</script>

<style scoped>
.containerImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: space-between;
  @media (max-width: 1260px) {
    justify-content: center;
  }
}

</style>